<template>
  <div>
    <div class="bankbox">
      <img src="@/assets/images/new/back-icon.png" alt="" @click="bankgo">
      <p class="bankname">帮助中心</p>
    </div>
    <div v-for="item in tipslist" :key="item.id" class="itembox">
      <img :src="item.icon" alt="">
      <a :href="item.url" target="_block">{{item.title}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipslist: [
        {
          id: 1,
          icon: require('../../assets/images/newuser/mb-user-tipsicon.png'),
          title: '打不开Steam怎么办？',
          url: 'https://www.bilibili.com/video/BV15D4y1X79w/'
        },
        {
          id: 2,
          icon: require('../../assets/images/newuser/mb-user-tipsicon.png'),
          title: '获取你的Steam链接',
          url: 'https://steamcommunity.com/profiles/76561198294948713/tradeoffers/privacy#trade_offer_access_url'
        },
        {
          id: 3,
          icon: require('../../assets/images/newuser/mb-user-tipsicon.png'),
          title: '取回前请将您的库存设置为“公开”',
          url: 'https://steamcommunity.com/profiles/76561198294948713/edit/settings/'
        },
      ]
    };
  },
  methods: {
    bankgo() {
      window.history.go(-1);
    },
  }

}
</script>

<style lang="scss" scoped>
.bankbox {
  width: 3.35rem;
  margin: 0 auto;
  height: 0.44rem;
  display: flex;
  align-items: center;
  background: #020715;
  text-align: center;
  position: relative;
  img {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
  }
  .bankname {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.16rem;
    color: #ffffff;
  }
}
.itembox {
  display: flex;
  align-items: center;
  margin: 0.2rem;
  img {
    width: 0.14rem;
    margin-right: 0.12rem;
  }
  a {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #dadada;
  }
}
</style>